import './App.css';
import { useEffect } from 'react'; 

function App() {
  useEffect(()=>{

  },[])
  return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
